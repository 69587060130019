<template>
  <div class="row">
    <div class="col-xs-12">
      <box>
        <p slot="content">Silence is golden.</p>
      </box>
      <box color="danger" :solid="true">
        <p slot="content">Silence is golden 2.</p>
      </box>
      <box color="warning" :solid="true" :open="!loading" :loading="loading">
        <p slot="content">Silence is golden 3.</p>
        <p slot="footer">Footer here.</p>
      </box>
      <box :open="false">
        <p slot="content">Silence is golden.</p>
      </box>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Dashboard'
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    setTimeout(() => { this.loading = false }, 2000)
  }
}
</script>
